import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "./baner.css";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Baner() {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    fetchImageData();
  }, []);

  const fetchImageData = async () => {
    try {
      const response = await fetch('https://dev.erckerala.org/api/banner');
      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }
      const data = await response.json();
      const urls = data.data.map(item => item.image);
      setImageUrls(urls);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  return (
   
      // <div className="baner-main-aling"> 
      <Container style={{marginTop:'0'}}>
        <Row style={{alignItems:'center'}} className="mt-2" id="baner">
          {/* left options */}
          <Col sm={12} lg={2} md={2} className="left-options">
            <Link to="/petitionpage">
              <div className="baner-box">
                <div>
                  <img
                    className="petition-icon"
                    src={require("../../../img/Petitions-icon.png")}
                  />
                  <Fragment>
                    <div className="Regulations-text">
                      Petitions
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/scheduleofhearing">
              <div className="baner-box-7">
                <div>
                  <img
                    className="Schedule-icon"
                    src={require("../../../img/shedule-icon.png")}
                  />
                  <Fragment>
                    <div className="Schedule-text">
                      Schedule of Hearing
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/orderpage">
              <div className="baner-box-6">
                <div>
                  {" "}
                  <img
                    className="orders-icon"
                    src={require("../../../img/order-ic.png")}
                  />
                  <br></br>
                  <Fragment>
                    {" "}
                    <div className="orders-text">
                      Orders
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/regulationspage">
              <div className="baner-box-4">
                <div>
                  <img
                    className="Regulations-icon"
                    src={require("../../../img/Regulation-icon.png")}
                  />
                  <Fragment>
                    {" "}
                    <div className="Regulations-text">
                      Regulations
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/pressrelease" >
              <div className="baner-box-9">
                <div>
                  <img
                    width="32"
                    height="32"
                    src="https://img.icons8.com/material/24/FFFFFF/magazine.png"
                    alt="magazine"
                    id="press-icon"
                  />
                  <Fragment>
                    {" "}
                    <div className="Consumer-text">
                      Press Release
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/reports">
              <div className="baner-box-10">
                <div>
                  {" "}
                  <img
                    width="32"
                    height="32"
                    src="https://img.icons8.com/external-sbts2018-outline-sbts2018/58/FFFFFF/external-reports-basic-ui-elements-2.3-sbts2018-outline-sbts2018.png"
                    alt="external-reports-basic-ui-elements-2.3-sbts2018-outline-sbts2018"
                    className="cgrf-icon"
                  />
                  <Fragment>
                    <div className="Schedule-text">
                      Reports
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
          </Col>
         {/* left option end */}
         
            {/* Swiper */}
        <Col  className="baner-1-conrainer"  sm={12} lg={8} md={8} >
          
            <Swiper style={{borderRadius:'14px',marginTop:'0'}}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
                autoplay: true,
              }}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              speed={2000}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {imageUrls.map((imageUrl, index) => (
                <SwiperSlide style={{borderRadius:'14px'}} key={index}>
                  <img className="baner-img" src={imageUrl} alt={`Slide ${index}`} />
                </SwiperSlide>
              ))}
            </Swiper>
         
        </Col>
        {/* Swiper end */}
  
          {/* right options */}
          <Col sm={12} lg={2} md={2} className="right-options">
            <Link to="/licensees">
              <div className="baner-box-2">
                <div>
                  <img
                    className="Licensees-icon"
                    src={require("../../../img/licence-icon.png")}
                  />
                  <Fragment>
                    {" "}
                    <div className="Regulations-text">
                      Licensees
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/ombudsman">
              <div className="baner-box-3">
                <div>
                  {" "}
                  <img
                    width="32"
                    height="32"
                    src="https://img.icons8.com/material/24/FFFFFF/administrator-male--v1.png"
                    alt="administrator-male--v1"
                    className="ombudsman-icon"
                  />
                  <Fragment>
                    <div className="Schedule-text">
                      Ombudsman
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/cgrf">
              <div className="baner-box-5">
                <div>
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/material/48/FFFFFF/conference-call--v2.png"
                    alt="conference-call--v2"
                    className="cgrf-icon"
                  />
                  <Fragment>
                    {" "}
                    <div className="cgrf-text">
                      CGRF
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/consumer_advocacy_cell">
              <div className="baner-box-8">
                <div>
                  <img
                    width="34"
                    height="34"
                    src="https://img.icons8.com/ios-filled/50/FFFFFF/morale.png"
                    alt="morale"
                    className="cac-icon"
                    id='cac-icon'
                  />
                  <Fragment>
                    {" "}
                    <div className="advocacy-text">
                      Consumer Advocacy & Compliance Examination
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/quotations">
              <div className="baner-box-11">
                <div>
                  <img
                    width="34"
                    height="34"
                    src="https://img.icons8.com/external-smashingstocks-glyph-smashing-stocks/66/FFFFFF/external-quotation-mark-testimonials-and-feedback-smashingstocks-glyph-smashing-stocks.png"
                    alt="external-quotation-mark-testimonials-and-feedback-smashingstocks-glyph-smashing-stocks"
                    className="quote-icon"
                  />
                  <Fragment>
                    {" "}
                    <div className="cgrf-text">
                      Quotations
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
            <Link to="/other_documents">
              <div className="baner-box-12">
                <div>
                  <img
                    width="32"
                    height="32"
                    src="https://img.icons8.com/ios-filled/50/FFFFFF/document--v1.png"
                    alt="document--v1"
                    className="doc-icon"
                  />
                  <Fragment>
                    <div className="Schedule-text">
                      Other Documents
                    </div>
                  </Fragment>
                </div>
              </div>
            </Link>
          </Col>
          {/* right option ends */}
        </Row>
      </Container>
      // </div>
    // </div>
    // </div>
  );
}

// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from "react-bootstrap";
// import "swiper/css/navigation";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination } from "swiper/modules";
// import "swiper/css";
// import "./baner.css";
// import { Fragment } from "react";
// import { Link } from "react-router-dom";

// export default function Baner() {
//   const [bannerData, setBannerData] = useState([]);

//   useEffect(() => {
//     fetchImageData();
//   }, []);

//   const fetchImageData = async () => {
//     try {
//       const response = await fetch('https://dev.erckerala.org/api/banner');
//       if (!response.ok) {
//         throw new Error('Failed to fetch banner data');
//       }
//       const data = await response.json();
//       setBannerData(data.data); // Store the entire banner data array
//     } catch (error) {
//       console.error('Error fetching banner data:', error);
//     }
//   };

//   return (
//     <Container style={{ marginTop: '0' }}>
//       <Row style={{ alignItems: 'center' }} className="mt-2" id="baner">
//         {/* left options */}
//         <Col sm={12} lg={2} md={2} className="left-options">
//           <Link to="/petitionpage">
//             <div className="baner-box">
//               <div>
//                 <img
//                   className="petition-icon"
//                   src={require("../../../img/Petitions-icon.png")}
//                 />
//                 <Fragment>
//                   <div className="Regulations-text">
//                     Petitions
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/scheduleofhearing">
//             <div className="baner-box-7">
//               <div>
//                 <img
//                   className="Schedule-icon"
//                   src={require("../../../img/shedule-icon.png")}
//                 />
//                 <Fragment>
//                   <div className="Schedule-text">
//                     Schedule of Hearing
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/orderpage">
//             <div className="baner-box-6">
//               <div>
//                 {" "}
//                 <img
//                   className="orders-icon"
//                   src={require("../../../img/order-ic.png")}
//                 />
//                 <br></br>
//                 <Fragment>
//                   {" "}
//                   <div className="orders-text">
//                     Orders
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/regulationspage">
//             <div className="baner-box-4">
//               <div>
//                 <img
//                   className="Regulations-icon"
//                   src={require("../../../img/Regulation-icon.png")}
//                 />
//                 <Fragment>
//                   {" "}
//                   <div className="Regulations-text">
//                     Regulations
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/pressrelease">
//             <div className="baner-box-9">
//               <div>
//                 <img
//                   width="32"
//                   height="32"
//                   src="https://img.icons8.com/material/24/FFFFFF/magazine.png"
//                   alt="magazine"
//                   id="press-icon"
//                 />
//                 <Fragment>
//                   {" "}
//                   <div className="Consumer-text">
//                     Press Release
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/reports">
//             <div className="baner-box-10">
//               <div>
//                 {" "}
//                 <img
//                   width="32"
//                   height="32"
//                   src="https://img.icons8.com/external-sbts2018-outline-sbts2018/58/FFFFFF/external-reports-basic-ui-elements-2.3-sbts2018-outline-sbts2018.png"
//                   alt="external-reports-basic-ui-elements-2.3-sbts2018-outline-sbts2018"
//                   className="cgrf-icon"
//                 />
//                 <Fragment>
//                   <div className="Schedule-text">
//                     Reports
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//         </Col>
//         {/* left option end */}

//         {/* Swiper */}
//         <Col className="baner-1-conrainer" sm={12} lg={8} md={8}>
//           <Swiper
//             style={{ borderRadius: '14px', marginTop: '0' }}
//             autoplay={{
//               delay: 2000,
//               disableOnInteraction: false,
//             }}
//             pagination={{
//               clickable: true,
//               autoplay: true,
//             }}
//             slidesPerView={1}
//             spaceBetween={30}
//             loop={true}
//             speed={2000}
//             modules={[Pagination, Autoplay]}
//             className="mySwiper"
//           >
//             {bannerData.map((banner, index) => (
//               <SwiperSlide style={{ borderRadius: '14px' }} key={banner.id}>
//                 <img 
//                   className="baner-img" 
//                   src={banner.image} 
//                   alt={banner.heading || `Slide ${index}`} 
//                 />
//                 {/* You can also display the heading if needed */}
//                 {/* <div className="banner-heading">{banner.heading}</div> */}
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </Col>
//         {/* Swiper end */}

//         {/* right options */}
//         <Col sm={12} lg={2} md={2} className="right-options">
//           <Link to="/licensees">
//             <div className="baner-box-2">
//               <div>
//                 <img
//                   className="Licensees-icon"
//                   src={require("../../../img/licence-icon.png")}
//                 />
//                 <Fragment>
//                   {" "}
//                   <div className="Regulations-text">
//                     Licensees
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/ombudsman">
//             <div className="baner-box-3">
//               <div>
//                 {" "}
//                 <img
//                   width="32"
//                   height="32"
//                   src="https://img.icons8.com/material/24/FFFFFF/administrator-male--v1.png"
//                   alt="administrator-male--v1"
//                   className="ombudsman-icon"
//                 />
//                 <Fragment>
//                   <div className="Schedule-text">
//                     Ombudsman
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/cgrf">
//             <div className="baner-box-5">
//               <div>
//                 <img
//                   width="30"
//                   height="30"
//                   src="https://img.icons8.com/material/48/FFFFFF/conference-call--v2.png"
//                   alt="conference-call--v2"
//                   className="cgrf-icon"
//                 />
//                 <Fragment>
//                   {" "}
//                   <div className="cgrf-text">
//                     CGRF
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/consumer_advocacy_cell">
//             <div className="baner-box-8">
//               <div>
//                 <img
//                   width="34"
//                   height="34"
//                   src="https://img.icons8.com/ios-filled/50/FFFFFF/morale.png"
//                   alt="morale"
//                   className="cac-icon"
//                   id='cac-icon'
//                 />
//                 <Fragment>
//                   {" "}
//                   <div className="advocacy-text">
//                     Consumer Advocacy & Compliance Examination
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/quotations">
//             <div className="baner-box-11">
//               <div>
//                 <img
//                   width="34"
//                   height="34"
//                   src="https://img.icons8.com/external-smashingstocks-glyph-smashing-stocks/66/FFFFFF/external-quotation-mark-testimonials-and-feedback-smashingstocks-glyph-smashing-stocks.png"
//                   alt="external-quotation-mark-testimonials-and-feedback-smashingstocks-glyph-smashing-stocks"
//                   className="quote-icon"
//                 />
//                 <Fragment>
//                   {" "}
//                   <div className="cgrf-text">
//                     Quotations
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//           <Link to="/other_documents">
//             <div className="baner-box-12">
//               <div>
//                 <img
//                   width="32"
//                   height="32"
//                   src="https://img.icons8.com/ios-filled/50/FFFFFF/document--v1.png"
//                   alt="document--v1"
//                   className="doc-icon"
//                 />
//                 <Fragment>
//                   <div className="Schedule-text">
//                     Other Documents
//                   </div>
//                 </Fragment>
//               </div>
//             </div>
//           </Link>
//         </Col>
//         {/* right option ends */}
//       </Row>
//     </Container>
//   );
// }





// <Swiper
// autoplay={{
//  delay: 3000,
//  disableOnInteraction: false,
// }}
// pagination={{
//  clickable: true,
//  autoplay:true,
// }}
// modules={[Autoplay, Pagination]}
// className="mySwiper">
//  <SwiperSlide>
//    <img
//      className="baner-img"
//      src={require("../../../img/Gallery/HB1.jpg")}
//    />
//  </SwiperSlide>
//  <SwiperSlide>
//    <img
//      className="baner-img"
//      src={require("../../../img/Gallery/HB2.jpg")}
//    />
//  </SwiperSlide>
//  <SwiperSlide>
//    <img
//      className="baner-img"
//      src={require("../../../img/Gallery/HB3.jpg")}
//    />
//  </SwiperSlide>
// </Swiper>
