// import React, { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
// import { Col, Container, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import './feedbackpage.css';
// import CaptchaTest from './CaptchaTest';

// export default function FeedbackPage() {
//   return (
//     <div>
//        <Container fluid className='right-to-information' >
//                 <Row>
//                     <Col md={12}>
//                         <div className="organisation-img">
//                             <h1 className='organisation-head'>Feedbacks</h1>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//          <Container >
//           <div className='form-feedback'>
//           <Form>
//           <Row>
//         <Form.Group>
//           <Form.Label>Full Name</Form.Label>
//         <Form.Control type="text" placeholder="Full name" id="feedback-input"/>
//         </Form.Group>
//         <Form.Group as={Col} controlId="formGridEmail" className='mt-3'>
//           <Form.Label>Email</Form.Label>
//           <Form.Control type="email" placeholder="Enter email" id="feedback-input" />
//         </Form.Group>
//       <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//         <Form.Label>Address</Form.Label>
//         <Form.Control as="textarea" rows={3} placeholder='Enter Address' id="feedback-input" />
//       </Form.Group>
//    <Form.Group className='mt-3'>
//        <Form.Label>Select</Form.Label >
//       <Form.Select aria-label="Default select example" id="feedback-input">
//       <option>Open this select menu</option>
//       <option value="1">Reporting a problem</option>
//       <option value="2">Suggestions</option>
//       <option value="3">Complaints</option>
//       <option value="4">User Experience</option>
//       <option value="5">Compliments</option>
//       <option value="6">Others</option>
//     </Form.Select>
//    </Form.Group>
//       <Form.Group controlId="formFile" className="mt-3" >
//         <Form.Label>Upload File</Form.Label>
//         <Form.Control type="file" id="feedback-input" />
//       </Form.Group>
//       <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1" >
//         <Form.Label>Remark</Form.Label>
//         <Form.Control as="textarea" rows={3} placeholder='Remarks...' id="feedback-input" />
//       </Form.Group>
//       <CaptchaTest/>
//       </Row>
//       <Button variant="primary" type="submit" className="mt-3">
//         Submit
//       </Button>
//     </Form>
//           </div>
//          </Container>
//     </div>
//   )
// }
// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import { Col, Container, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import './feedbackpage.css';
// import CaptchaTest from './CaptchaTest';

// export default function FeedbackPage() {
//   const [formData, setFormData] = useState({
//     fullName: '',
//     email: '',
//     address: '',
//     type: '',
//     remarks: '',
//     file: null,
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     setFormData({
//       ...formData,
//       file: e.target.files[0],
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formDataToSend = new FormData();
//     formDataToSend.append('fullName', formData.fullName);
//     formDataToSend.append('email', formData.email);
//     formDataToSend.append('address', formData.address);
//     formDataToSend.append('type', formData.type);
//     formDataToSend.append('remarks', formData.remarks);
//     formDataToSend.append('file', formData.file);

//     try {
//       const response = await fetch('https://dev.erckerala.org/api/feedback', {
//         method: 'POST',
//         body: formDataToSend,
//       });

//       if (response.ok) {
//         alert('Feedback submitted successfully!');
//       } else {
//         alert('Failed to submit feedback');
//       }
//     } catch (error) {
//       console.error('Error submitting feedback:', error);
//       alert('Error submitting feedback');
//     }
//   };

//   return (
//     <div>
//       <Container fluid className="right-to-information">
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className="organisation-head">File a Complaint</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Container>
//         <div className="form-feedback">
//           <Form onSubmit={handleSubmit}>
//             <Row>
//               <Form.Group>
//                 <Form.Label>Full Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Full name"
//                   name="fullName"
//                   value={formData.fullName}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <Form.Group as={Col} controlId="formGridEmail" className="mt-3">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   placeholder="Enter email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <Form.Group as={Col} controlId="formGridPhone" className="mt-3">
//   <Form.Label>Ph Number</Form.Label>
//   <Form.Control
//     type="tel"
//     placeholder="Enter phone number"
//     name="phone"  
//     value={formData.phone}  
//     onChange={handleChange}
//     id="feedback-input"
//   />
// </Form.Group>


//               <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//                 <Form.Label>Address</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   placeholder="Enter Address"
//                   name="address"
//                   value={formData.address}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3">
//                 <Form.Label>Select</Form.Label>
//                 <Form.Select
//                   aria-label="Default select example"
//                   name="type"
//                   value={formData.type}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 >
//                   <option>Open this select menu</option>
//                   <option value="1">Reporting a problem</option>
//                   <option value="2">Suggestions</option>
//                   <option value="3">Complaints</option>
//                   <option value="4">User Experience</option>
//                   <option value="5">Compliments</option>
//                   <option value="6">Others</option>
//                 </Form.Select>
//               </Form.Group>
//               {/* <Form.Group controlId="formFile" className="mt-3">
//                 <Form.Label>Upload File</Form.Label>
//                 <Form.Control
//                   type="file"
//                   name="file"
//                   onChange={handleFileChange}
//                   id="feedback-input"
//                 />
//               </Form.Group> */}
//               <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//                 <Form.Label>Remark</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   placeholder="Remarks..."
//                   name="remarks"
//                   value={formData.remarks}
//                   onChange={handleChange}
//                   id="feedback-input"
//                 />
//               </Form.Group>
//               <CaptchaTest />
//             </Row>
//             <Button variant="primary" type="submit" className="mt-3">
//               Submit
//             </Button>
//           </Form>
//         </div>
//       </Container>
//     </div>
//   );
// }


// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import { Col, Container, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import './feedbackpage.css';
// import CaptchaTest from './CaptchaTest';

// export default function FeedbackPage() {
//   const [formData, setFormData] = useState({
//     fullName: '',
//     email: '',
//     phone: '',
//     address: '',
//     street: '',
//     city: '',
//     district: '',
//     pinCode: '',
//     complaint_nature: '',
//     complaint_type: '',
//     remarks: '',
//     file: null,
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     setFormData({
//       ...formData,
//       file: e.target.files[0],
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     // Validate complaint_nature before submission
//     if (!formData.complaint_nature || formData.complaint_nature === "") {
//       alert("Please select a valid complaint nature");
//       return;
//     }
  
//     const submissionData = {
//       fullname: formData.fullname,
//       email: formData.email,
//       phone: formData.phone,
//       address: formData.address,
//       street: formData.street,
//       city: formData.city,
//       district: formData.district,
//       pincode: formData.pinCode,
//       complaint_nature: formData.complaint_nature,
//       complaint_type: formData.complaint_nature === "Others" ? formData.complaint_type : "",
//       remarks: formData.remarks,
//     };
  
//     try {
//       const response = await fetch('https://dev.erckerala.org/api/feedback', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           "Accept": "application/json",
//         },
//         body: JSON.stringify(submissionData),
//       });
  
//       console.log("Response Status:", response.status);
      
//       const text = await response.text();
//       console.log("Raw response:", text);
  
//       if (!response.ok) {
//         alert(`Error: ${response.status} - ${response.statusText}`);
//         return;
//       }
  
//       try {
//         const responseData = JSON.parse(text);
//         alert('Submitted successfully!');
//         // Optionally reset the form here
//         setFormData({
//           fullname: '',
//           email: '',
//           phone: '',
//           address: '',
//           street: '',
//           city: '',
//           district: '',
//           pinCode: '',
//           complaint_nature: '',
//           complaint_type: '',
//           remarks: '',
//           file: null,
//         });
//       } catch (jsonError) {
//         console.error("JSON Parse Error:", jsonError);
//         alert("Unexpected response from server. Check console for details.");
//       }
//     } catch (error) {
//       console.error('Submission error:', error);
//       alert(`Network error - ${error.message}`);
//     }
//   };
  
  
//   return (
//     <div>
//       <Container fluid className="right-to-information">
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className="organisation-head">File a Complaint</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Container style={{ marginTop: '20px' }}>
//         <div className="form-feedback">
//           <Form onSubmit={handleSubmit}>
//             <Row>
//               <Form.Group>
//                 <Form.Label>Full Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Full name"
//                   name="fullName"
//                   value={formData.fullname}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group as={Col} controlId="formGridEmail" className="mt-3">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   placeholder="Enter email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group as={Col} controlId="formGridPhone" className="mt-3">
//                 <Form.Label>Phone Number</Form.Label>
//                 <Form.Control
//                   type="tel"
//                   placeholder="Enter phone number"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//                 <Form.Label>Address Line</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter Address Line"
//                   name="address"
//                   value={formData.address}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3" controlId="street">
//                 <Form.Label>Street</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter Street"
//                   name="street"
//                   value={formData.street}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3" controlId="city">
//                 <Form.Label>City</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter City"
//                   name="city"
//                   value={formData.city}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3" controlId="district">
//                 <Form.Label>District</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter District"
//                   name="district"
//                   value={formData.district}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3" controlId="pinCode">
//                 <Form.Label>PIN Code</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter PIN Code"
//                   name="pinCode"
//                   value={formData.pinCode}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mt-3">
//   <Form.Label>Nature of Complaint</Form.Label>
//   <Form.Select
//     aria-label="Default select example"
//     name="complaint_nature"
//     value={formData.complaint_nature}
//     onChange={handleChange}
//     id="feedback-input"
//     required
//   >
//     <option value="">Open this select menu</option>
//     <option value="Complaint">Complaint</option>
//     <option value="Petition">Petition</option>
//     <option value="Feedback">Feedback</option>
//     <option value="Suggestion">Suggestion</option>
//     <option value="Others">Others</option>
//   </Form.Select>
// </Form.Group>

// {/* Show additional input only if "Others" is selected */}
// {formData.complaint_nature === "Others" && (
//   <Form.Group className="mt-3" controlId="complaint_type">
//     <Form.Label>Specify Type</Form.Label>
//     <Form.Control
//       type="text"
//       placeholder="Enter the type"
//       name="complaint_type"
//       value={formData.complaint_type || ""}
//       onChange={handleChange}
//       id="feedback-input"
//       required
//     />
//   </Form.Group>
// )}
//               <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
//                 <Form.Label>Remark</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   placeholder="Remarks..."
//                   name="remarks"
//                   value={formData.remarks}
//                   onChange={handleChange}
//                   id="feedback-input"
//                   required
//                 />
//               </Form.Group>
//               <CaptchaTest />
//             </Row>
//             <Button variant="primary" type="submit" className="mt-3">
//               Submit
//             </Button>
//           </Form>
//         </div>
//       </Container>
//     </div>
//   );
// }
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './feedbackpage.css';
import CaptchaTest from './CaptchaTest';

export default function FeedbackPage() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    street: '',
    city: '',
    district: '',
    pinCode: '',
    type: '',          // Main complaint type (will be numeric: 1-5)
    otherType: '',     // Only needed if type is "5" (Others)
    remarks: '',
    file: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Required fields based on backend requirements
    const requiredFields = {
      fullName: "Full Name is required",
      email: "Email is required",
      phone: "Phone Number is required",
      address: "Address is required",
      street: "Street is required",
      city: "City is required",
      district: "District is required",
      pinCode: "PIN Code is required",
      type: "Complaint Type is required",
      remarks: "Remarks are required"
    };

    Object.entries(requiredFields).forEach(([field, message]) => {
      if (!formData[field]) {
        newErrors[field] = message;
      }
    });

    // Phone number validation (10 digits)
    if (formData.phone && !/^[0-9]{10}$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
    }

    // PIN code validation (6 digits)
    if (formData.pinCode && !/^[0-9]{6}$/.test(formData.pinCode)) {
      newErrors.pinCode = "Please enter a valid 6-digit PIN code";
    }

    // Email validation
    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Other type validation (only if type is "5" - Others)
    if (formData.type === "5" && !formData.otherType) {
      newErrors.otherType = "Please specify the complaint type";
    }
    if (formData.type === "5" && !formData.otherType.trim()) {
      newErrors.otherType = "Please specify the complaint type";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;
  
    const submissionData = {
      fullName: formData.fullName.substring(0, 191),
      email: formData.email.substring(0, 191),
      phone: formData.phone.substring(0, 20),
      address: formData.address.substring(0, 191),
      street: formData.street.substring(0, 191),
      city: formData.city.substring(0, 191),
      district: formData.district.substring(0, 191),
      pinCode: formData.pinCode.substring(0, 10),
      type: formData.type === "5" ? "Other" : formData.type,
      otherType: (formData.type === "5" ? formData.otherType : "").substring(0, 191),
      remarks: formData.remarks.substring(0, 500),
      created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
      updated_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
    };
  
    console.log("Final submission data:", submissionData);
  
    try {
      const response = await fetch('https://dev.erckerala.org/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });
  
      // First get the response text regardless of content type
      const responseText = await response.text();
      
      try {
        // Try to parse as JSON
        const result = JSON.parse(responseText);
        
        if (!response.ok) {
          // Handle API-level errors (like validation errors)
          if (response.status === 422 && result.errors) {
            const apiErrors = {};
            Object.keys(result.errors).forEach(key => {
              apiErrors[key] = result.errors[key][0];
            });
            setErrors(apiErrors);
          }
          throw new Error(result.message || "Submission failed");
        }
        
        // Success case
        console.log('API Response:', result);
        alert('Submitted successfully!');
        // Reset form
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          address: '',
          street: '',
          city: '',
          district: '',
          pinCode: '',
          type: '',
          otherType: '',
          remarks: '',
          file: null,
        });
        setErrors({});
        
      } catch (jsonError) {
        // If JSON parsing fails, handle as text response
        if (!response.ok) {
          throw new Error(responseText || `HTTP error! status: ${response.status}`);
        }
        // If response is OK but not JSON, treat as success
        console.log('API Response (non-JSON):', responseText);
        alert('Submitted successfully!');
        // Reset form
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          address: '',
          street: '',
          city: '',
          district: '',
          pinCode: '',
          type: '',
          otherType: '',
          remarks: '',
          file: null,
        });
        setErrors({});
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <Container fluid className="right-to-information">
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">File a Complaint</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: '20px' }}>
        <div className="form-feedback">
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    isInvalid={!!errors.fullName}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number *</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter 10-digit phone number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Address *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    isInvalid={!!errors.address}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Street *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Street"
                    name="street"
                    value={formData.street}
                    onChange={handleChange}
                    isInvalid={!!errors.street}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.street}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>City *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>District *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter District"
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    isInvalid={!!errors.district}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.district}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>PIN Code *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter 6-digit PIN Code"
                    name="pinCode"
                    value={formData.pinCode}
                    onChange={handleChange}
                    isInvalid={!!errors.pinCode}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pinCode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Complaint Type *</Form.Label>
                  <Form.Select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    isInvalid={!!errors.type}
                    required
                  >
                    <option value="">Select a type</option>
                    <option value="1">Complaint</option>
                    <option value="2">Feedback</option>
                    <option value="3">Suggestion</option>
                    <option value="4">Appreciation</option>
                    <option value="5">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {formData.type === "5" && (
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Specify Other Type *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Please specify"
                      name="otherType"
                      value={formData.otherType}
                      onChange={handleChange}
                      isInvalid={!!errors.otherType}
                      required={formData.type === "5"}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.otherType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Remarks *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your remarks"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                    isInvalid={!!errors.remarks}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.remarks}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <CaptchaTest />
              </Col>

              <Col md={12} className="text-center mt-4">
                <Button variant="primary" type="submit" size="lg">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
}
// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import { Col, Container, Row } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import './feedbackpage.css';
// import CaptchaTest from './CaptchaTest';
// export default function FeedbackPage() {
//   const [formData, setFormData] = useState({
//     fullName: '', // Changed to match backend
//     email: '',
//     phone: '',
//     address: '',
//     street: '',
//     city: '',
//     district: '',
//     pinCode: '', // Changed to match backend
//     complaint_nature: '',
//     complaint_type: '',
//     remarks: '',
//   });

//   const [errors, setErrors] = useState({});
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [submitSuccess, setSubmitSuccess] = useState(false);
//   const [serverError, setServerError] = useState('');

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     // Clear error when user starts typing
//     if (errors[name]) {
//       setErrors({
//         ...errors,
//         [name]: null
//       });
//     }
//     // Clear server error when user makes any change
//     if (serverError) {
//       setServerError('');
//     }
//   };

//   const validateForm = () => {
//     const newErrors = {};
    
//     // Required fields based on backend requirements
//     const requiredFields = {
//       fullName: "Full Name is required",
//       email: "Email is required",
//       phone: "Phone Number is required",
//       address: "Address is required",
//       street: "Street is required",
//       city: "City is required",
//       district: "District is required",
//       pinCode: "PIN Code is required",
//       complaint_nature: "Complaint Nature is required",
//       remarks: "Remarks are required"
//     };

//     Object.entries(requiredFields).forEach(([field, message]) => {
//       if (!formData[field]) {
//         newErrors[field] = message;
//       }
//     });

//     // Phone number validation (10 digits)
//     if (formData.phone && !/^[0-9]{10}$/.test(formData.phone)) {
//       newErrors.phone = "Please enter a valid 10-digit phone number";
//     }

//     // PIN code validation (6 digits)
//     if (formData.pinCode && !/^[0-9]{6}$/.test(formData.pinCode)) {
//       newErrors.pinCode = "Please enter a valid 6-digit PIN code";
//     }

//     // Email validation
//     if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
//       newErrors.email = "Please enter a valid email address";
//     }

//     // Complaint type validation (only if nature is "Others")
//     if (formData.complaint_nature === "Others" && !formData.complaint_type) {
//       newErrors.complaint_type = "Please specify the complaint type";
//     }

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setSubmitSuccess(false);
//     setServerError('');
    
//     if (!validateForm()) return;
  
//     setIsSubmitting(true);
    
//     // Prepare data exactly as backend expects
//     const submissionData = {
//       fullName: formData.fullName,
//       email: formData.email,
//       phone: formData.phone,
//       address: formData.address,
//       street: formData.street,
//       city: formData.city,
//       district: formData.district,
//       pinCode: formData.pinCode,
//       complaint_nature: formData.complaint_nature === "Others" ? "Other" : formData.complaint_nature,
//       complaint_type: formData.complaint_nature === "Others" ? formData.complaint_type : "",
//       remarks: formData.remarks,
//     };
  
//     try {
//       const response = await fetch('https://dev.erckerala.org/api/feedback', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Accept': 'application/json',
//         },
//         body: JSON.stringify(submissionData),
//       });
    
//       const result = await response.json();
      
//       if (!response.ok) {
//         // Handle 422 validation errors
//         if (response.status === 422 && result.errors) {
//           const apiErrors = {};
//           Object.keys(result.errors).forEach(key => {
//             // Map backend field names to our frontend field names
//             apiErrors[key] = result.errors[key][0];
//           });
//           setErrors(apiErrors);
//           setServerError('Please correct the highlighted errors');
//           return;
//         }
//         // Handle other errors
//         setServerError(result.message || "Submission failed. Please try again.");
//         return;
//       }
    
//       // Success case
//       setSubmitSuccess(true);
//       // Reset form
//       setFormData({
//         fullName: '',
//         email: '',
//         phone: '',
//         address: '',
//         street: '',
//         city: '',
//         district: '',
//         pinCode: '',
//         complaint_nature: '',
//         complaint_type: '',
//         remarks: '',
//       });
//       setErrors({});
//     } catch (error) {
//       console.error('Error:', error);
//       setServerError("An error occurred. Please try again.");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };


//   return (
//     <div>
//             <Container fluid className="right-to-information">
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className="organisation-head">File a Complaint</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Container style={{ marginTop: '20px' }}>
//         {submitSuccess && (
//           <div className="alert alert-success mb-4">
//             Your complaint has been submitted successfully!
//           </div>
//         )}

//         {serverError && (
//           <div className="alert alert-danger mb-4">
//             {serverError}
//           </div>
//         )}
        

        
//         <div className="form-feedback">
//           <Form onSubmit={handleSubmit} noValidate>
//             <Row>
//               <Col md={12}>
//               <Form.Group className="mb-3">
//                   <Form.Label>Full Name *</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Full name"
//                     name="fullName"  // Changed to match backend
//                     value={formData.fullName}
//                     onChange={handleChange}
//                     isInvalid={!!errors.fullName}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.fullName}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={6}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Email *</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     isInvalid={!!errors.email}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.email}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={6}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Phone Number *</Form.Label>
//                   <Form.Control
//                     type="tel"
//                     placeholder="Enter 10-digit phone number"
//                     name="phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                     isInvalid={!!errors.phone}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.phone}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={12}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Address *</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter Address"
//                     name="address"
//                     value={formData.address}
//                     onChange={handleChange}
//                     isInvalid={!!errors.address}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.address}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={12}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Street *</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter Street"
//                     name="street"
//                     value={formData.street}
//                     onChange={handleChange}
//                     isInvalid={!!errors.street}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.street}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>City *</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter City"
//                     name="city"
//                     value={formData.city}
//                     onChange={handleChange}
//                     isInvalid={!!errors.city}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.city}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>District *</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter District"
//                     name="district"
//                     value={formData.district}
//                     onChange={handleChange}
//                     isInvalid={!!errors.district}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.district}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>PIN Code *</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter 6-digit PIN Code"
//                     name="pincode"
//                     value={formData.pincode}
//                     onChange={handleChange}
//                     isInvalid={!!errors.pincode}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.pincode}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={12}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Complaint Nature *</Form.Label>
//                   <Form.Select
//                     name="complaint_nature"
//                     value={formData.complaint_nature}
//                     onChange={handleChange}
//                     isInvalid={!!errors.complaint_nature}
//                     required
//                   >
//                     <option value="">Select a type</option>
//                     <option value="Complaint">Complaint</option>
//                     <option value="Feedback">Feedback</option>
//                     <option value="Suggestion">Suggestion</option>
//                     <option value="Others">Other</option>
//                   </Form.Select>
//                   <Form.Control.Feedback type="invalid">
//                     {errors.complaint_nature}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               {formData.complaint_nature === "Others" && (
//                 <Col md={12}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Specify Complaint Type *</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Please specify"
//                       name="complaint_type"
//                       value={formData.complaint_type}
//                       onChange={handleChange}
//                       isInvalid={!!errors.complaint_type}
//                       required={formData.complaint_nature === "Others"}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.complaint_type}
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>
//               )}

//               <Col md={12}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Remarks *</Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     rows={3}
//                     placeholder="Enter your remarks"
//                     name="remarks"
//                     value={formData.remarks}
//                     onChange={handleChange}
//                     isInvalid={!!errors.remarks}
//                     required
//                   />
//                   <Form.Control.Feedback type="invalid">
//                     {errors.remarks}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//               </Col>

//               <Col md={12}>
//                 <CaptchaTest />
//               </Col>

//               <Col md={12} className="text-center mt-4">
//                 <Button 
//                   variant="primary" 
//                   type="submit" 
//                   size="lg"
//                   disabled={isSubmitting}
//                 >
//                   {isSubmitting ? 'Submitting...' : 'Submit'}
//                 </Button>
//               </Col>

//             </Row>
//           </Form>
//         </div>
//       </Container>
//     </div>
//   );
// }