// import React, { useState } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import Table from 'react-bootstrap/Table';
// import './careerspage.css';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import { Link } from 'react-router-dom';

// export default function Internship() {

//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");
//   const [filteredData, setFilteredData] = useState([]);

//   // Dummy data for internships
//   const dummyInternships = [
//     { id: 1, job_title: ' KSERC invites applications for Internship Programme 2025', description: 'KSERC invites applications from fresh B.Tech/M.Tech Electricl/ Electrical & Electronics Engineering graduates for Internship Programme 2025', last_date: '2025-03-25', file:'/Internship_Notification_2025.pdf'  },
   
//   ];

//   const [internships, setInternships] = useState(dummyInternships);

//   const formatDateYYMMDDtoDDMMYY = (dateString) => {
//     if (!dateString || dateString.length < 6) return dateString; // Handle undefined or invalid formats

//     let formattedDate = "";
//     if (dateString.includes('-')) { 
//         // Handling possible formats like "24-02-13"
//         const parts = dateString.split('-');
//         if (parts.length === 3) {
//             formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert yymmdd -> dd-mm-yyyy
//         }
//     } else {
//         // Handling "240213" format (yymmdd)
//         const year = dateString.slice(0, 2);
//         const month = dateString.slice(2, 4);
//         const day = dateString.slice(4, 6);
//         formattedDate = `${day}-${month}-${year}`; // Convert yymmdd -> dd-mm-yyyy
//     }

//     return formattedDate;
//   };

//   const years = [];
//   const currentYear = new Date().getFullYear();
//   for (let year = currentYear; year >= 2001; year--) {
//     years.push(year);
//   }

//   const handleSearchInputChange = (e) => {
//     const searchTerm = e.target.value;
//     setSearchItem(searchTerm);

//     const filteredItems = internships.filter((data) =>
//       data.job_title.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     setFilteredData(filteredItems);
//   }

//   const handleYearFilterChange = (e) => {
//     const searchTerm = e.target.value;
//     setFilterYear(searchTerm);

//     const filteredItems = internships.filter((data) =>
//       data.last_date.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     setFilteredData(filteredItems);
//   }

//   return (
//     <div>
//       <Container fluid className='career'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Internships</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Container style={{marginTop:'20px'}}>
//         <Row className='wrapper'>
//           <Col className='mt-2' md={4} id="archives-search-area">
//             <Form.Select
//               aria-label="Default select example"
//               value={filterYear}
//               onChange={handleYearFilterChange}
//             >
//               <option value="">Year</option>
//               {years.map(year => (
//                 <option key={year} value={year}>{year}</option>
//               ))}
//             </Form.Select>
//           </Col>
//           <Col md={4} className='form-aling mt-2' id='archives-form'>
//             <FloatingLabel controlId="floatingInputGrid" label="Keyword Search..">
//               <Form.Control
//                 type="text"
//                 placeholder="Search"
//                 value={searchItem}
//                 onChange={handleSearchInputChange}
//               />
//             </FloatingLabel>
//           </Col>
//           <Col className='mt-2' md={4} id='search-btn1'  style={{textAlign: 'center',height:'42px' }}> 
//             <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button>
//           </Col>
//         </Row>
//       </Container>
//       <Container style={{marginTop:'10px'}}>
//         <Row >
//           <Col style={{ overflowX: 'auto' }} md={12} className='wrapper'>
//             <Table striped bordered hover variant="light">
//               <thead>
//                 <tr>
//                   <th>Title</th>
//                   <th>Description</th>
//                   <th style={{width:'100px'}}>Last Date</th>
//                   <th>Download</th>
//                   <th>Apply</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredData.length > 0 ? filteredData.map(internship => (
//                   <tr key={internship.id}>
//                     <td>{internship.job_title}</td>
//                     <td>{internship.description}</td>
//                     <td>{formatDateYYMMDDtoDDMMYY(internship.last_date)}</td>
//                     <td>
//                       <div className='ordrs-icon-aling'>
//                         <Link to={internship.file} target='blank'>view</Link>
//                       </div>
//                     </td>
//                     <td>
//                       <div className='ordrs-icon-aling'>
//                         <Link to={`/apply/${internship.id}`} target='blank'>Apply</Link>
//                       </div>
//                     </td>
//                   </tr>
//                 )) : internships.map(internship => (
//                   <tr key={internship.id}>
//                     <td>{internship.job_title}</td>
//                     <td>{internship.description}</td>
//                     <td>{formatDateYYMMDDtoDDMMYY(internship.last_date)}</td>
//                     <td>
//                       <div className='ordrs-icon-aling'>
//                         <Link to={internship.file} target='blank'>view</Link>
//                       </div>
//                     </td>
//                     <td>
//                       <div className='ordrs-icon-aling'>
//                       <Link to="https://kserc.sbs/internship_2025/" target="_blank">Apply</Link>

//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   )
// }

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './careerspage.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

export default function Internship() {
  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [internships, setInternships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInternships = async () => {
      try {
        const response = await fetch('https://dev.erckerala.org/api/internship');
        if (!response.ok) {
          throw new Error('Failed to fetch internships');
        }
        const data = await response.json();
        
        // Ensure data is an array before setting it
        if (Array.isArray(data)) {
          setInternships(data);
        } else if (data && Array.isArray(data.data)) {
          // Handle case where response has data property containing array
          setInternships(data.data);
        } else if (data && Array.isArray(data.items)) {
          // Handle case where response has items property containing array
          setInternships(data.items);
        } else {
          // If data is not an array, wrap it in an array
          setInternships([data]);
        }
        
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
        setInternships([]); // Set empty array on error
      }
    };

    fetchInternships();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return dateString;
      }
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (e) {
      return dateString;
    }
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2001; year--) {
    years.push(year);
  }

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = internships.filter((data) =>
      data.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.job_title?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  const handleYearFilterChange = (e) => {
    const year = e.target.value;
    setFilterYear(year);

    if (!year) {
      setFilteredData([]);
      return;
    }

    const filteredItems = internships.filter((data) => {
      const date = data.date || data.last_date;
      if (!date) return false;
      
      try {
        const itemYear = new Date(date).getFullYear();
        return itemYear.toString() === year;
      } catch {
        return false;
      }
    });

    setFilteredData(filteredItems);
  };

  const displayData = filteredData.length > 0 ? filteredData : internships;

  if (loading) {
    return <div className="text-center py-5">Loading internships...</div>;
  }

  if (error) {
    return <div className="text-center py-5 text-danger">Error: {error}</div>;
  }

  return (
    <div>
      <Container fluid className='career'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Internships</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{marginTop:'20px'}}>
        <Row className='wrapper'>
          <Col className='mt-2' md={4} id="archives-search-area">
            <Form.Select
              aria-label="Default select example"
              value={filterYear}
              onChange={handleYearFilterChange}
            >
              <option value="">Year</option>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </Form.Select>
          </Col>
          <Col md={4} className='form-aling mt-2' id='archives-form'>
            <FloatingLabel controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange}
              />
            </FloatingLabel>
          </Col>
          <Col className='mt-2' md={4} id='search-btn1' style={{textAlign: 'center',height:'42px'}}> 
            <button style={{width:'100%'}} type="button" className="btn btn-outline-primary">Search</button>
          </Col>
        </Row>
      </Container>
      <Container style={{marginTop:'10px'}}>
        <Row>
          <Col style={{ overflowX: 'auto' }} md={12} className='wrapper'>
            {!displayData || displayData.length === 0 ? (
              <div className="text-center py-5">No internships available</div>
            ) : (
              <Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th style={{width:'100px'}}>Last Date</th>
                    <th>Download</th>
                    <th>Apply</th>
                  </tr>
                </thead>
                <tbody>
                  {displayData.map((internship, index) => (
                    <tr key={internship.id || index}>
                      <td>{internship.title || internship.job_title || 'N/A'}</td>
                      <td>{internship.description || 'N/A'}</td>
                      <td>{formatDate(internship.date || internship.last_date)}</td>
                      <td>
                        <div className='ordrs-icon-aling'>
                          {internship.file_upload || internship.file ? (
                            <Link to={internship.file_upload || internship.file} target='_blank' rel='noopener noreferrer'>
                              View
                            </Link>
                          ) : 'N/A'}
                        </div>
                      </td>
                      <td>
                        <div className='ordrs-icon-aling'>
                          {internship.url ? (
                            <Link to={internship.url} target='_blank' rel='noopener noreferrer'>
                              Apply
                            </Link>
                          ) : 'N/A'}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}