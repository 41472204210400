// import React from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import './ombudsman.css';
// import Button from 'react-bootstrap/Button';
// import { Link } from "react-router-dom";

// // Custom component for opening links in a new tab
// const ExternalLink = ({ href, children }) => {
//     const handleClick = (e) => {
//         e.preventDefault();
//         window.open(href, '_blank');
//     };

//     return <span onClick={handleClick}>{children}</span>;
// };

// export default function Ombudsman() {
//     return (
//         <div style={{marginBottom:"30px"}}>
//             <Container fluid className='right-to-information'>
//                 <Row>
//                     <Col md={12}>
//                         <div className="organisation-img">
//                             <h1 className='organisation-head'> Electricity Ombudsman</h1>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//             <Container>
//                 <Row className='wrapper'>
//                     <Col md={6}>
//                         <img className='ombuds-img' src={require('../../img/ombuds-img.jpeg')} alt="Ombudsman" />
//                     </Col>
//                     <Col>
//                         <h4>Shri . A. Chandrakumaran Nair,
//                         </h4>
//                         <p className='ombudsman-content'>Electricity Ombudsman is a statutory authority appointed or designated by the State
//                             Commission. Any consumer aggrieved by non-redressal of the grievance under CGRF, may
//                             approach the Electricity Ombudsman. The Ombudsman has been entrusted with the task of
//                             settling the grievance of consumers as per the provisions of KSERC (Consumer Grievance
//                             Redressal and Electricity Ombudsman)
//                             Regulations 2005.</p>
//                         <p className='ombudsman-location-head'>The office of the Electricity Ombudsman located at :</p>
//                         <p className='ombudsman-location-text'>The State Electricity Ombudsman,
//                             D.H Road, Offshore Road Junction,<br /> Near Gandhi Square,
//                             Ernakulam, Kerala - 682 016</p>
//                         <p className='ombudsman-contact'>Phone : <span className='ombudsman-contact-numb' >0484-2346488</span> </p>
//                         <p className='ombudsman-contact'>Mobile : <span className='ombudsman-contact-numb' >+91 8714356488</span> </p>
//                         <p className='ombudsman-contact'>Email : <span className='ombudsman-contact-numb' ><Link to='mailto:ombudsman.electricity@gmail.com'>ombudsman.electricity@gmail.com</Link></span> </p>
//                         <p className='ombudsman-contact'>Website : <span className='ombudsman-contact-numb' style={{cursor:'pointer',color:'#3d71ff'}} ><ExternalLink  href='https://www.keralaeo.org'> www.keralaeo.org </ExternalLink></span></p>
//                         <p className='ombudsman-latest-text'>For latest Orders of the Ombudsman<span><ExternalLink href='https://www.keralaeo.org'><Button className='ombudsman-bt' variant="">Click here</Button></ExternalLink></span></p>
//                     </Col>
//                 </Row>
//             </Container>
//         </div>
//     )
// }

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ombudsman.css';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

const ExternalLink = ({ href, children }) => {
  const handleClick = (e) => {
    e.preventDefault();
    // Add https:// if the URL doesn't start with http:// or https://
    let formattedUrl = href;
    if (!href.startsWith('http://') && !href.startsWith('https://')) {
      formattedUrl = `https://${href}`;
    }
    // Remove any trailing slashes for consistency
    formattedUrl = formattedUrl.replace(/\/+$/, '');
    window.open(formattedUrl, '_blank');
  };

  return <span onClick={handleClick} style={{ cursor: 'pointer' }}>{children}</span>;
};

export default function Ombudsman() {
  const [ombudsmanData, setOmbudsmanData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOmbudsmanData = async () => {
      try {
        const response = await fetch('https://dev.erckerala.org/api/ombudsman');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const result = await response.json();
        console.log('API Response:', result);
        
        if (result.data && result.data.length > 0) {
          const data = result.data[0];
          // Ensure website has proper protocol if it exists
          if (data.website && !data.website.startsWith('http')) {
            data.website = `https://${data.website}`;
          }
          setOmbudsmanData(data);
        } else {
          throw new Error('No ombudsman data available');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching ombudsman data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchOmbudsmanData();
  }, []);

  if (loading) {
    return <div className="text-center py-5">Loading...</div>;
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center my-5">
        Error loading ombudsman data: {error}
      </div>
    );
  }

  if (!ombudsmanData) {
    return (
      <div className="alert alert-warning text-center my-5">
        No ombudsman data available
      </div>
    );
  }

  return (
    <div style={{ marginBottom: "30px" }}>
      <Container fluid className='right-to-information'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>
                Electricity Ombudsman
              </h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='wrapper'>
          <Col md={6}>
            <img 
              className='ombuds-img' 
              src={ombudsmanData.image_upload ? `https://dev.erckerala.org/${ombudsmanData.image_upload}` : require('../../img/ombuds-img.jpeg')} 
              alt={ombudsmanData.name || 'Ombudsman'} 
            />
          </Col>
          <Col>
            <h4>{ombudsmanData.name}</h4>
            <p className='ombudsman-content'>{ombudsmanData.description}</p>
            <p className='ombudsman-location-head'>The office of the Electricity Ombudsman located at:</p>
            <p className='ombudsman-location-text'>{ombudsmanData.address}</p>
            <p className='ombudsman-contact'>Phone: <span className='ombudsman-contact-numb'>{ombudsmanData.phone}</span></p>
            <p className='ombudsman-contact'>Mobile: <span className='ombudsman-contact-numb'>{ombudsmanData.mobile}</span></p>
            <p className='ombudsman-contact'>Email: <span className='ombudsman-contact-numb'>
              <Link to={`mailto:${ombudsmanData.email}`}>{ombudsmanData.email}</Link>
            </span></p>
            {ombudsmanData.website && (
              <p className='ombudsman-contact'>Website: <span className='ombudsman-contact-numb'>
                <ExternalLink href={ombudsmanData.website}>{ombudsmanData.website.replace(/^https?:\/\//, '')}</ExternalLink>
              </span></p>
            )}
            {ombudsmanData.order_link && (
              <p className='ombudsman-latest-text'>
                For latest Orders of the Ombudsman
                <span>
                  <ExternalLink href={ombudsmanData.order_link}>
                    <Button className='ombudsman-bt' variant="">Click here</Button>
                  </ExternalLink>
                </span>
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}