// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from "react-bootstrap";
// import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import Table from 'react-bootstrap/Table';
// import './otherdocuments.css';
// import { Link } from "react-router-dom";
// import APIService from '../../APIService';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';

// export default function OtherDocuments() {

//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");

//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);

//   useEffect(() => {
//     APIService.get('other_documents')
//       .then((response) => {
//         setData(response.data);
//         setFilteredData(response.data);
//       })
//   }, []);

//   const handleSearchInputChange = (e) => {
//     const searchTerm = e.target.value;
//     setSearchItem(searchTerm);

//     const filteredItems = data.filter((data) =>
//       data.title.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     setFilteredData(filteredItems);
//   }

//   const handleYearFilterChange = (e) => {
//     const searchTerm = e.target.value;
//     setFilterYear(searchTerm);

//     const filteredItems = data.filter((data) =>
//       data.year.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     setFilteredData(filteredItems);
//   }

//   // Generate years dynamically from current year to 2001
//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year);
//     }
//     return years;
//   }

//   return (
//     <div>
//       <Container fluid className='StateAdvisory'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Other Documents</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Container style={{marginTop:'20px'}}>
//         <Row className='wrapper'>
//           <Col className='mt-2' md={4} id="archives-search-area">
        
//               <Form.Select
//                 aria-label="Default select example"
//                 value={filterYear}
//                 onChange={handleYearFilterChange}
//               >
//                 <option value="">Year</option>
//                 {generateYears().map((year, index) => (
//                   <option key={index} value={year}>{year}</option>
//                 ))}
//               </Form.Select>
            
//           </Col>
//           <Col md={4} className='form-aling mt-2' id='archives-form'>
//             <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
//               <Form.Control
//                 type="text"
//                 placeholder="Search"
//                 value={searchItem}
//                 onChange={handleSearchInputChange}
//               />
//             </FloatingLabelSerch>
//             {/* <button type="button" className="btn btn-outline-primary" id='search-btn'>Search</button> */}
//           </Col>
//           <Col className='mt-2' md={4} id='search-btn1'  style={{textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
//         </Row>
//       </Container>
//       <Container style={{overflowX:'auto',marginTop:'10px'}} >
//         <Table striped bordered hover variant="light">
//           <thead>
//             <tr>
//               <th>Sl No</th>
//               <th>Title</th>
//               <th>Year</th>
//               <th>Download</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.map((data, index) => (
//               <tr key={data.id}>
//                 <td>{index + 1}</td>
//                 <td>{data.title}</td>
//                 <td>{data.year}</td>
//                 <td> <div className='ordrs-icon-aling'>  <Link to={data.file} target='blank'>View</Link> </div></td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </Container>
//     </div>
//   )
// }
import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Spinner } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import './otherdocuments.css';
import { Link } from "react-router-dom";
import APIService from '../../APIService';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function OtherDocuments() {
  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    APIService.get('other_documents')
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
      });
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    filterData(searchTerm, filterYear);
  };

  const handleYearFilterChange = (e) => {
    const year = e.target.value;
    setFilterYear(year);
    filterData(searchItem, year);
  };

  const filterData = (searchTerm, yearFilter) => {
    let filteredItems = data;
    
    if (searchTerm) {
      filteredItems = filteredItems.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    }
    
    if (yearFilter) {
      filteredItems = filteredItems.filter(item =>
        item.year.toLowerCase().includes(yearFilter.toLowerCase())
    );
    }
    
    setFilteredData(filteredItems);
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year);
    }
    return years;
  };

  const handleDownloadZip = async (documentId, files) => {
    setLoadingStates(prev => ({ ...prev, [documentId]: true }));

    try {
      const zip = new JSZip();
      const folder = zip.folder(`document_${documentId}`);

      // Add main document
      if (files.mainFile) {
        const mainResponse = await fetch(files.mainFile);
        const mainBlob = await mainResponse.blob();
        folder.file('main_document.pdf', mainBlob);
      }

      // Add additional files
      if (files.additionalFiles && files.additionalFiles.length > 0) {
        await Promise.all(files.additionalFiles.map(async (file, index) => {
          try {
            const response = await fetch(file.docs);
            const blob = await response.blob();
            folder.file(`attachment_${index + 1}.pdf`, blob);
          } catch (error) {
            console.error(`Error downloading attachment ${index + 1}:`, error);
          }
        }));
      }

      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `document_${documentId}_bundle.zip`);
    } catch (error) {
      console.error('Error creating ZIP file:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, [documentId]: false }));
    }
  };

  return (
    <div>
      <Container fluid className='StateAdvisory'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Other Documents</h1>
            </div>
          </Col>
        </Row>
      </Container>
      
      <Container style={{ marginTop: '20px' }}>
        <Row className='wrapper'>
          <Col className='mt-2' md={4} id="archives-search-area">
            <Form.Select
              aria-label="Filter by year"
              value={filterYear}
              onChange={handleYearFilterChange}
            >
              <option value="">All Years</option>
              {generateYears().map((year, index) => (
                <option key={index} value={year}>{year}</option>
              ))}
            </Form.Select>
          </Col>
          
          <Col md={4} className='form-aling mt-2' id='archives-form'>
            <FloatingLabel controlId="floatingInputGrid" label="Keyword Search">
              <Form.Control
                type="text"
                placeholder="Search documents..."
                value={searchItem}
                onChange={handleSearchInputChange}
              />
            </FloatingLabel>
          </Col>
          
          <Col className='mt-2' md={4} id='search-btn1' style={{ textAlign: 'center' }}>
            <Button style={{ width: '100%' }} variant="outline-primary">Search</Button>
          </Col>
        </Row>
      </Container>
      
      <Container style={{ overflowX: 'auto', marginTop: '10px' }}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Year</th>
              <th>View</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.year}</td>
                <td>
                  <div className='ordrs-icon-aling'>
                    <Link to={item.file} target='_blank' rel="noopener noreferrer">
                      View
                    </Link>
                  </div>
                </td>
                <td>
                  <div className='ordrs-icon-aling'>
                    {Array.isArray(item["0"]) && item["0"].length > 0 ? (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleDownloadZip(item.id, {
                          mainFile: item.file,
                          additionalFiles: item["0"]
                        })}
                        disabled={loadingStates[item.id]}
                      >
                        {loadingStates[item.id] ? (
                          <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            {' Preparing...'}
                          </>
                        ) : (
                          'Download All as ZIP'
                        )}
                      </Button>
                    ) : (
                      <span className="text-muted">No attachments</span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}