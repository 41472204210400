// import React, { useState } from 'react';
// import { Col, Container, Row } from "react-bootstrap";
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import Table from 'react-bootstrap/Table';
// import { Link, useNavigate } from "react-router-dom";

// export default function Research() {
//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");

//   // Dummy Static Data
//   const dummyData = [
//     { id: 1, title: "Smart Grid Implementation in Kerala", year: "13-02-2023", image: "/images/research1.jpg", document: "/documents/research1.pdf" },
//     { id: 2, title: "Energy Storage Solutions for Renewable Power in Kerala", year: "13-02-2022", image: "/images/research2.jpg", document: "/documents/research2.pdf" },
//     { id: 3, title: "Solar Power Integration in Kerala", year: "12-05-2021", image: "/images/research3.jpg", document: "/documents/research3.pdf" },
//     { id: 4, title: "Impact of Hydroelectric Dams on Local Ecosystem", year: "13-02-2020", image: "/images/research4.jpg", document: "/documents/research4.pdf" },
//     { id: 5, title: "Advancements in Wind Energy Utilization in Kerala", year: "12-04-2019", image: "/images/research5.jpg", document: "/documents/research5.pdf" }
//   ];

//   const [filteredData, setFilteredData] = useState(dummyData);
//   const navigate = useNavigate();

//   const handleSearchInputChange = (e) => {
//     const searchTerm = e.target.value;
//     setSearchItem(searchTerm);

//     const filteredItems = dummyData.filter((item) =>
//       item.title.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     setFilteredData(filteredItems);
//   };

//   const handleYearFilterChange = (e) => {
//     const searchTerm = e.target.value;
//     setFilterYear(searchTerm);

//     const filteredItems = dummyData.filter((item) =>
//       item.year.includes(searchTerm)
//     );

//     setFilteredData(filteredItems);
//   };

//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year);
//     }
//     return years;
//   };

//   const handleDownload = (e, documentUrl) => {
//     e.stopPropagation(); // Prevent row click event
//     const link = document.createElement('a');
//     link.href = documentUrl;
//     link.download = documentUrl.split('/').pop();
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   const handleView = (e, id) => {
//     e.stopPropagation(); // Prevent row click event
//     navigate(`/research/${id}`);
//   };

//   return (
//     <div>
//       <Container fluid className='StateAdvisory'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Research Repository</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Container style={{ marginTop: '20px' }}>
//         <Row className='wrapper'>
//           <Col className='mt-2' md={4} id="archives-search-area">
//             <Form.Select
//               aria-label="Default select example"
//               value={filterYear}
//               onChange={handleYearFilterChange}
//             >
//               <option value="">Year</option>
//               {generateYears().map((year, index) => (
//                 <option key={index} value={year}>{year}</option>
//               ))}
//             </Form.Select>
//           </Col>
//           <Col md={4} className='form-aling mt-2' id='archives-form'>
//             <FloatingLabel className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
//               <Form.Control
//                 type="text"
//                 placeholder="Search"
//                 value={searchItem}
//                 onChange={handleSearchInputChange}
//               />
//             </FloatingLabel>
//           </Col>
//           <Col className='mt-2' md={4} id='search-btn1' style={{ textAlign: 'center' }}>
//             <button style={{ width: '100%' }} type="button" className="btn btn-outline-primary">Search</button>
//           </Col>
//         </Row>
//       </Container>
//       <Container style={{ overflowX: 'auto', marginTop: '10px' }}>
//         <Table striped bordered hover variant="light">
//           <thead>
//             <tr>
//               <th style={{ width: '40px' }}>Sl No</th>
//               <th>Title</th>
//               <th >Date</th>
//               {/* <th style={{ textAlign: 'center' }}>Download</th> */}
              
//             </tr>
//           </thead>
//           {/* <tbody>
//             {filteredData.map((item, index) => (
//               <tr key={item.id} style={{ cursor: 'pointer' }}>
//                 <td style={{ width: '40px' }}>{index + 1}</td>
//                 <td>{item.title}</td>
//                 <td style={{ width: '120px' }}>{item.year}</td>
//                 <td style={{ width: '93.72px' }}>
//                   <div className='ordrs-icon-aling'>
//                     <button
//                       className="btn btn-link p-0"
//                       onClick={(e) => handleDownload(e, item.document)}
//                     >
//                       Download
//                     </button>
//                   </div>
//                 </td>
               
//               </tr>
//             ))}
//           </tbody> */}
//         </Table>
//       </Container>
//     </div>
//   );
// }
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import axios from 'axios';

export default function Research() {
  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [researchData, setResearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Enhanced API data fetching
  useEffect(() => {
    const fetchResearchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://dev.erckerala.org/api/research', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        // Debugging logs
        console.group('API Response Analysis');
        console.log('Full Response:', response);
        console.log('Status:', response.status);
        console.log('Data Type:', typeof response.data);
        console.log('Is Array:', Array.isArray(response.data));
        
        // Handle different API response structures
        let data = [];
        if (Array.isArray(response.data)) {
          data = response.data;
        } else if (Array.isArray(response.data?.data)) {
          data = response.data.data;
        } else if (Array.isArray(response.data?.results)) {
          data = response.data.results;
        } else if (response.data && typeof response.data === 'object') {
          // If single item, wrap in array
          data = [response.data];
        }

        console.log('Processed Data:', data);
        console.log('First Item Keys:', data[0] ? Object.keys(data[0]) : 'No items');
        console.groupEnd();

        setResearchData(data);
        setFilteredData(data);
      } catch (err) {
        console.error('API Error:', {
          message: err.message,
          response: err.response?.data,
          status: err.response?.status
        });
        setError(err.response?.data?.message || err.message);
        setResearchData([]);
        setFilteredData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchResearchData();
  }, []);

  // Enhanced search handler
  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    applyFilters(searchTerm, filterYear);
  };

  // Enhanced year filter handler
  const handleYearFilterChange = (e) => {
    const year = e.target.value;
    setFilterYear(year);
    applyFilters(searchItem, year);
  };

  // Combined filtering logic
  const applyFilters = (searchTerm, yearFilter) => {
    const filteredItems = researchData.filter((item) => {
      const titleMatch = item.title?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false;
      const dateMatch = yearFilter === "" || extractYear(item.date) === yearFilter;
      return titleMatch && dateMatch;
    });
    setFilteredData(filteredItems);
  };

  // Robust year extraction from date
  const extractYear = (dateString) => {
    if (!dateString) return "";
    
    // Try parsing as Date object first
    const dateObj = new Date(dateString);
    if (!isNaN(dateObj)) return dateObj.getFullYear().toString();

    // Fallback to string parsing
    const parts = dateString.split(/[-/]/);
    if (parts.length >= 3) {
      // Check for YYYY-MM-DD or DD-MM-YYYY formats
      return parts[0].length === 4 ? parts[0] : 
             parts[2].length === 4 ? parts[2] : "";
    }
    return "";
  };

  // Generate years for dropdown
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year);
    }
    return years;
  };

  // Handle document opening
  const handleDocumentOpen = (documentUrl) => {
    if (documentUrl) {
      window.open(documentUrl, '_blank', 'noopener,noreferrer');
    }
  };

  // Loading and error states
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <span className="ms-3">Loading research data...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger mx-3 my-5">
        <h4>Error Loading Data</h4>
        <p>{error}</p>
        <button 
          className="btn btn-primary"
          onClick={() => window.location.reload()}
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="research-container">
     
      
            <div class="organisation-img">
              <h1 className='organisation-head' style={{textAlign:'center'}}>Research Repository</h1>
            </div>
        
   

      <Container className="filter-container my-4 ">
        <Row className="g-3">
          <Col md={4}>
            <Form.Select
              aria-label="Filter by year"
              value={filterYear}
              onChange={handleYearFilterChange}
              className="year-selector"
            >
              <option value="">All Years</option>
              {generateYears().map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </Form.Select>
          </Col>
          
          <Col md={4}>
            <FloatingLabel controlId="searchInput" label="Search by keyword">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange}
                className="search-input"
              />
            </FloatingLabel>
          </Col>
          
          <Col md={4} className="d-flex align-items-center">
            <button 
              className="btn btn-outline-primary w-100"
              onClick={() => applyFilters(searchItem, filterYear)}
            >
              Search
            </button>
          </Col>
        </Row>
      </Container>

      <Container className="results-container">
        {filteredData.length === 0 ? (
          <div className="no-results text-center py-5">
            <h4>No research papers found</h4>
            <p className="text-muted">
              {researchData.length === 0 
                ? 'The server returned no data. Please try again later.' 
                : 'Try adjusting your search criteria.'}
            </p>
          </div>
        ) : (
          <div className="table-responsive">
            <Table striped bordered hover className="research-table">
              <thead className="table-header">
                <tr>
                  <th style={{width:'50px'}}>Sl No</th>
                  <th style={{width:'65%'}}>Title</th>
                  <th style={{width:'30%'}} width="30%">Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr 
                    key={item.id || index}
                    onClick={() => handleDocumentOpen(item.file || item.documentUrl || item.url)}
                    className={item.file ? 'clickable-row' : ''}
                  >
                    <td style={{width:'50px'}}>{index + 1}</td>
                    <td style={{width:'65%'}}>{item.title || item.name || 'Untitled Research'}</td>
                    <td style={{width:'30%'}}>
                      {item.date 
                        ? new Date(item.date).toLocaleDateString('en-IN', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                          })
                        : 'Date not available'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Container>
    </div>
  );
}