// import Navn from 'react-bootstrap/Nav';
// import Navbart from 'react-bootstrap/Navbar';
// // import './navbar.css';
// import '../Navbar/navbar2.css';
// import { Link } from 'react-router-dom';

// export default function Navbar() {
//   return (
//     <div className='top'>
//       <Navbart expand="lg" className="navbar-back-colur navbar-dark">
//         <Navbart.Toggle aria-controls="basic-navbar-nav" />
//         <Navbart.Collapse id="basic-navbar-nav">
//           <Navn className="me-auto ms-auto">
//             <Link role='button' className='nav-text-home nav-link' to="/">Home</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/gallery">Gallery</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/righttoinformation">RTI</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/dashboard">Dashboard</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/state_coordination">State Co-ordination Forum</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/stateadvisory" >State Advisory Committee</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/startup" >Startup Entrepreneurship</Link>
//             {/* <Link role='button' className='nav-text-home nav-link' to="/entrepreneur" >Entrepreneur</Link> */}
//             <Link role='button' className='nav-text-home nav-link' to="/careerspage">Careers</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/archives">Archives</Link>
//             <Link role='button' className='nav-text-home nav-link' to="/events">Events</Link>
//             <div className="dropdown">
//               <button
//                 className=" dropdown-toggle"
//                 type="button"
//                 id="dropdownMenuButton"
//                 data-mdb-toggle="dropdown"
//                 aria-expanded="false"
//               >
//                 About
//               </button>
//               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
//                 <li><Link className="dropdown-item" to="/commission">Commission</Link></li>
//                 <li><Link className="dropdown-item" to="organisation">Organisation</Link></li>
//                 <li><Link className="dropdown-item" to="history">History</Link></li>
//               </ul>
//             </div>
//             <Link role='button' className='nav-text-home nav-link' to="/contact">Contact us</Link>
//           </Navn>
//         </Navbart.Collapse>
//         {/* </Container> */}
//       </Navbart>
//     </div>
//   )
// }
import React, { useState } from 'react';
import Navn from 'react-bootstrap/Nav';
import Navbart from 'react-bootstrap/Navbar';
import '../Navbar/navbar2.css';
import { Link } from 'react-router-dom';

export default function Navbar() {
  // State to manage the collapse
  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    // Collapse the menu after a link is clicked
    setExpanded(false);
  };

  return (
    <div className='top'>
      <Navbart expanded={expanded} expand="lg" className="navbar-back-colur navbar-dark">
        <Navbart.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbart.Collapse id="basic-navbar-nav">
          <Navn className="me-auto ms-auto">
            <Link role='button' className='nav-text-home nav-link' to="/" onClick={handleNavItemClick}>Home</Link>
            <Link role='button' className='nav-text-home nav-link' to="/gallery" onClick={handleNavItemClick}>Gallery</Link>
            <Link role='button' className='nav-text-home nav-link' to="/righttoinformation" onClick={handleNavItemClick}>RTI</Link>
            <Link role='button' className='nav-text-home nav-link' to="/dashboard" onClick={handleNavItemClick}>Dashboard</Link>
            <Link role='button' className='nav-text-home nav-link' to="/state_coordination" onClick={handleNavItemClick}>State Co-ordination Forum</Link>
            <Link role='button' className='nav-text-home nav-link' to="/stateadvisory" onClick={handleNavItemClick}>State Advisory Committee</Link>
            {/* <Link role='button' className='nav-text-home nav-link' to="/startup" onClick={handleNavItemClick}>Startup Entrepreneurship</Link> */}
            <div className="dropdown">
              <button
                className=" dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
                 Research & Entrepreneurship
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><Link className="dropdown-item" to="/research" onClick={handleNavItemClick}>Research Repository</Link></li>
                <li><Link className="dropdown-item" to="/startup" onClick={handleNavItemClick}>Startup Entrepreneurship</Link></li>
                
              </ul>
            </div>
            <div className="dropdown">
              <button
                className=" dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
                Opportunities
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
           <li> <Link className="dropdown-item"  to="/careerspage" onClick={handleNavItemClick}>Careers</Link></li>
           <li> <Link className="dropdown-item"   to="/internship" onClick={handleNavItemClick}>Internships <span style={{fontSize:'16px',color:'red',fontWeight:700}} className='blink-hard2 ms-3'>new</span> </Link></li>
           </ul>
           </div>

            <Link role='button' className='nav-text-home nav-link' to="/archives" onClick={handleNavItemClick}>Archives</Link>
            <Link role='button' className='nav-text-home nav-link' to="/events" onClick={handleNavItemClick}>Events</Link>
            <div className="dropdown">
              <button
                className=" dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
                About
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><Link className="dropdown-item" to="/commission" onClick={handleNavItemClick}>Commission</Link></li>
                <li><Link className="dropdown-item" to="organisation" onClick={handleNavItemClick}>Organisation</Link></li>
                <li><Link className="dropdown-item" to="history" onClick={handleNavItemClick}>History</Link></li>
              </ul>
            </div>
            <Link role='button' className='nav-text-home nav-link' to="/contact" onClick={handleNavItemClick}>Contact us</Link>
          </Navn>
        </Navbart.Collapse>
      </Navbart>
    </div>
  )
}
